// These are the routes for the application

export const HOME = '/'
export const ABOUT = '/about'
export const MENU = '/menu'
export const CONTACT = '/contact'
export const SIGN_UP = '/signup'
export const SIGN_IN = '/signin'
export const DASHBOARD = '/dashboard'
export const INVOICES = '/invoices'






